@import "./index.scss";
.user-menu {
    display: none;
    position: absolute;
    background-color: #333333;
    top: 55px;
    right: 20px;
    border-radius: 10px;
    padding: 20px;
    &.show{
        display: block;
    }
}

// ================================= wice =================================
.wice-header {
    width: 100%;
    height: 78px;
    background-color: #000;
    padding: 0;
}

.wus-header {
    width: 100%;
    height: 78px;
    background-color: #FE2C55;
    padding: 0;
}

.header-position {
    position: fixed;
    top: 0;
    @include flex-column;
    align-items: start;
    justify-content: center;
    padding-left: 10%;
}

.wice-content {
    @include flex-column;
    p {
        margin: 20px 0px;
    }
}

.wice-button {
    border: 1px solid #333;
    a {
        color: #000;
        margin: 50px 100px;
    }
    padding:10px;
    border-radius: 8px;
    margin-top: 20px;
}

.download-modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba($color: #000000, $alpha: 0.3);
    left: 0;
    top: 0;
    z-index: 999;
    backdrop-filter: blur(4px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.download-content{
    background-color: #fff;
    width: 332px;
    height: 177px;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loading-modal-content{
    background-color: #fff;
    width: 150px;
    height: 150px;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-close-box {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: #333, $alpha: 0.6);
    border-radius: 20px;
    margin-top: 20px;
}

@media only screen and (max-width: 999px) {
    .download-content{
        width: 365px;
        height: 208px;
    }
}

