@import "./index.scss";
.buy-panel {
    width: 320px;
    height: 310px;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 30px 10px 30px;
    @include flex-column;
    align-items: start;
}

.confirm-button {
    background-color: $theme-color;
    border: 1px solid #fff;
    height: 40px;
    width: 200px;
    border-radius: 8px;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
}

.panel-charged {
    @include flex-row;
    align-items: baseline;
}