@import "./index.scss";
.contact-us {
    padding: 160px 0 80px 0;
}

.wus-contact-us{
    padding: 160px 0 80px 0;
    background-image: url("../../../public/bg.webp");
    background-repeat: no-repeat;
    background-size: 100% 70%;
    height: 100%;
    min-height: 725px;
}

.contact-us-title {
    text-align: center;
}

.contact-us-form {
    width: 800px;
    margin: 40px auto;
    p {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 350;
        font-size: 14px;
        line-height: 24px;
        color: #60616B;
    }
}

.contact-us-btn {
    margin: 0 auto;
    max-width: 480px;
    height: 44px;
    width: 80%;

    background: #FE2C55;
    border-radius: 12px;
    border: 1px solid #FE2C55;
}

.content-card {
    border-radius: 8px;
    background: var(--White, #FFF);
    box-shadow: 0px 1px 2px -2px rgba(0, 0, 0, 0.05);
    padding: 40px 80px;
    max-width: 954px;
}

.contact-us-btn-text{
    color: var(--White, #FFF);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    /* 六级-列表、小标题、大按钮文本/中粗|font_grade_6_medium */
    font-family: PingFang SC;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px; /* 153.333% */
}

.user-terms-item {
    margin-left: 10px;
    position: relative;
    margin-bottom: 10px;
}

.user-terms-item::before {
    content: '';
    position: absolute;
    display: block;
    height: 12px;
    width: 4px;
    background-color: $theme-color;
    color: $theme-color;
    left: -10px;
    top: 5px;
}

@media only screen and (max-width: 900px) {
    .contact-us-form {
        width: 95%;
    }
}

.trade-modal-view {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 104;
}

.tarde-modal-panel {
    position: absolute;
    width: 320px;
    height: 294px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    padding: 24px 16px;
}

.trade-modal-btn {
    width: 288px;
    height: 48px;
    font-size: 20px;
    margin: 10px auto;
    border-radius: 4px;
    padding-top: 8px;
}

.trade-modal-btn a {
    color: #000;
}

.btn-background {
    background-color: $theme-color;
}

.btn-border {
    border: 1px solid #CCCCCC;
}

.wait-point {
    width: 12px;
    height: 12px;
    background-color: #FFCD31;
    margin-left: 10px;
    border-radius: 6px;
}

.wait-plane {
    @include flex-row;
    justify-content: center;
    margin: 16px auto 50px auto;
}

.user-terms-body {
    padding: 136px;
    background: var(--gray-10, #F8F8F9);
    @include flex-row;
    justify-content: center;
    align-items: flex-start;
    background-size: cover;/* 设置容器高度为 100% */
    overflow: hidden;
    
}

.privacy-height {
    height: 100%;
    min-height: 796px;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
    .user-terms-body{
        padding: 84px 32px ;
    }
    
    .content-card{
        padding: 40px 40px;
    }
}

@media screen and (width < 768px) {
    .user-terms-body{
        padding: 84px 16px ;
    }
    .privacy-height {
        height: 100%;
        min-height: 1116px;
    }
    .content-card{
        padding: 40px 20px;
    }
}