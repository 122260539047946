@import "./index.scss";
.register-menu {
    @include flex-row;
    justify-content: space-between;
    #tips {
        display: none;
    }
    margin-bottom: 10px;
}

.register-panel {
    width: 320px;
    height: 300px;
    background: white;
    border-radius: 10px;
    padding: 24px;
    input {
        height: 36px;
    }
    text-align: center;
}

.register-panel-header {
    @include flex-row;
    justify-content: space-between;
    h1 {
        margin-bottom: 0;
    }
    margin-bottom: 10px;
}

.register-button {
    width: 100%;
    margin-top: 10px;
}

.verification-code-menu {
    @include flex-row;
    justify-content: space-between;
    .ant-form-item-control-input-content {
        input {
            margin-bottom: 0px;
        }
    }
    div {
        // margin-left: auto;
        // margin-right: auto;
        // padding: 10px;
        height: 40px;
    }
}

.trade-body {
    min-height: 1465px;
}

.trade-list {
    @include flex-row;
    justify-content: space-between;
    padding: 20px 120px 20px 120px;
}

.tarde-item {
    position: relative;
    flex: 1;
    @include flex-column;
    border: 1px solid #F3F4F5;
    box-sizing: border-box;
    box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.05);
    border-radius: 17.5px;
    padding: 40px 0 40px 0;
    width: 100%;
}

.trade-pay-method {
    @include flex-row;
    justify-content: space-between;
    padding: 20px 120px 20px 120px;
   
}
.wus-trade-discount-position{
    position: absolute;
    top: -8px;
    left: -1px;
}

.wus-trade-discount{
    width: 112.481px;
    height: 40.195px;
    flex-shrink: 0;
    background-image: url("../../asset/icon/union.svg");
    padding-top: 12px;
}

.wus-trade-discount-text {
    color: var(--White, #FFF);
    font-family: PingFang SC;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.trade-pay-method-item {
    flex: 1;
    border: 2px solid var(--primary-70, #FE2C55);
    box-sizing: border-box;
    border-radius: 12px;
    @include flex-row;
    justify-content: space-between;
    padding: 18px;
}

.trade-pay-method-item-right {
    @include flex-row;
}

.download-icon {
    fill: #F3F4F5;
}

.trade-pay-button {
    @include flex-row;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    background: linear-gradient(98deg, #FE364D 1.75%, #FE364D 67.65%, #FFB876 101.49%);
    width:  65.867vw;
    height: 9.067vw;
    max-width: 494px;
    max-height: 64px;
    // padding: 10px 0 5px 0;
    color: white;
    cursor: pointer;
}

.trade-pay-button-text{
    color: var(--White, #FFF);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: PingFang SC;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
}

.trade-pay-item-select-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 20px;
    right: 20px;
    fill: #FE364D;
    color: #FE364D;
}

.trade-discount {
    @include flex-column;
}

.windows-setup-download-body{
    border-radius: 20px;
    > .ant-modal-content > .ant-modal-body{
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}

.windows-download-panel{
    width: 332px;
    height: 177px;
}


.windows-download-button{
    width: 82px;
    height: 65px;
    border-radius: 10px;
    color: white;
    border: 0;
    font-size: medium;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.windows-download-btn-body{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}


@media only screen and (min-width: 1200px) {
    .tarde-item {
        margin-right: 3vw;
    }
    .trade-pay-method-item {
        margin-right: 10px;
    }
    .trade-body{
        min-height: 100vh;
    }
}

@media only screen and (max-width: 1199px) and (min-width: 1000px) {
    .tarde-item {
        margin-right: 10px;
    }
    .trade-pay-method-item {
        margin-right: 10px;
    }
    .trade-body{
        min-height: 100vh;
    }
}

@media only screen and (max-width: 999px) {
    .trade-list {
        @include flex-column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 20px 20px;
    }
    .tarde-item {
        align-items: flex-start;
        padding: 10px 30px;
        margin-right: 0;
        margin-bottom: 10px;
    }
    .trade-discount {
        @include flex-row;
        align-items: baseline;
        justify-content: space-between;
        flex-wrap: wrap;
        div {
            flex: 1;
            margin: 0 5px;
        }
    }
    .trade-pay-method-item {
        margin-right: 10px;
    }

    .windows-download-btn-body{
        flex-direction: column;
    }

    .windows-download-button{
        width: 243px;
        height: 45px;
        flex-direction: row;
        margin-bottom: 14px;
    }
}

@media only screen and (max-width: 879px) {
    .tarde-item {
        align-items: center;
        padding: 10px 30px;
    }
    .trade-pay-method {
        @include flex-column;
        padding: 20px 0px 20px 0px;
        margin-right: 0px;
    }
    .trade-pay-method-item {
        width: 100%;
        margin-bottom: 10px;
        margin-right: 0px;
    }
}

.trade-charge-p {
    color: var(--primary-70, #FE2C55);
    text-align: right;
    font-family: PingFang SC;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 2px;
}

.trade-charge-type-p {
    color: var(--gray-90, #393942);
    font-family: PingFang SC;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;
}

.trade-item-title {
    color: var(--gray-100, #27272D);
    text-align: center;
    font-family: PingFang SC;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.trade-old-charge {
    color: var(--gray-60, #707080);
    text-align: center;
    font-family: PingFang SC;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}